import { WINDOW } from '@ng-toolkit/universal';
import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Location, PlatformLocation, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { Globals } from './../globals';
import { OtpComponent } from '../otp/otp.component';
import { FirstdataComponent } from '../firstdata/firstdata.component';

declare var Razorpay: any;
declare var $: any;
declare let paypal: any;
declare let Stripe: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CheckoutComponent implements OnInit, OnDestroy {
  public globals: Globals;
  public language: any;
  route: any;
  public businessId: any;
  public businessDetails: any;
  public categoryDetails: any;
  public MenuDetails: any;
  public orderType: any;
  public CartDish: any;
  public SubTotal: any;
  public myClonedcategoryDetails: any;
  public buyerDetails: any;
  public discountInfo: any;
  public texttips: any;
  public Search: any;
  public preorder: any = false;
  public paymentMethod: any;
  public sourcetype: any;
  public sourcetypename: any;
  public discount: any;
  public tips: any;
  public cartFee: any;
  public CartSubTotal: any;
  public txnId: any;
  public postData: any;
  public orderId: any;
  public businessDaysObject: any;
  public businessTime: any;
  minDate = new Date();
  maxDate = new Date();
  public allCurrencyCode: any;
  public allCurrencySymbol: any;
  public paypalExpress = false;
  public paypalLoading = false;
  public addScript = false;
  public currencyCode = 'USD';
  public paypalTotal = '0.00';
  public paymentType = 'sandbox';
  public paypalSandBox = '';
  public paypalProduction = '';
  public paypalSecretSandBox = '';
  public paypalSecretProduction = '';
  public paypalConfig: any;
  public paymentEnabled = false;
  public otpverified: any;
  public preorderDetails: any;
  public menuTime: any;
  public preOrderText: any;
  public disabledASAP = false;
  public paymentId: any;
  public tipspercentage: any;
  public preOrderTime: any;
  public currentTime: any;
  public showPreorderTimeChange = false;
  public addressReadonly = true;
  public deliveryDetails: any;
  public customtips = false;
  public dateFilter: any;
  public dateClass: any;
  public laundryService: any = {
    pickupDate: '',
    pickupTime: '',
    deliveryDate: '',
    deliveryTime: ''
  };
  public laundryPickupDateFilter: any;
  public laundryPickupDateClass: any;
  public laundryPickupMinDate = new Date();
  public laundryPickupMaxDate = new Date();
  public laundryPickupMenuTime: any = [];
  public laundryDeliveryDateFilter: any;
  public laundryDeliveryDateClass: any;
  public laundryDeliveryMinDate = new Date();
  public laundryDeliveryMaxDate = new Date();
  public laundryDeliveryMenuTime: any = [];
  public rewardsData: any;
  stripeHandler: any = null;
  stripePublishKey: any = '';
  stripeSecretKey: any = '';
  public extraJson: any = {};
  RAZORPAY_KEY_ID:any;
  RAZORPAY_KEY_SECRET: any;
  public checked = false;

  @ViewChild('buyername') buyername!: ElementRef;
  @ViewChild('buyerlastname') buyerlastname!: ElementRef;
  @ViewChild('buyeraddress') buyeraddress!: ElementRef;
  @ViewChild('bspecialaddress') bspecialaddress!: ElementRef;
  @ViewChild('beircode', {static: false}) beircode: ElementRef;
  @ViewChild('buyeremail') buyeremail!: ElementRef;
  @ViewChild('buyermobile') buyermobile!: ElementRef;
  @ViewChild('tablenumber', {static: false}) tablenumber: ElementRef;

  constructor(
    @Inject(WINDOW) private window: Window,
    private cdRef: ChangeDetectorRef,
    public plocation: PlatformLocation,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    public location: Location,
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }
  ngOnInit() {
    setTimeout(() => {
      if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
        this.cdRef.detectChanges();
        this.comservice.scrollTop();
      }
    });
    this.txnId = '';
    this.discountInfo = new Object();
    this.discountInfo = {
      'discounttext': '',
      'discounttype': '',
      'discountid': ''
    };
    this.rewardsData = new Object();
    this.buyerDetails = new Object();

    this.CartDish = this.comservice.get('cartDetails');
    this.orderType = this.comservice.get('orderType');
    this.Search = this.comservice.get('Search');
    this.preorderDetails = this.comservice.get('preorderDetails');
    this.deliveryDetails = this.comservice.get('deliveryDetails');



    let stripeRedirect;
    stripeRedirect = this.comservice.get('stripeRedirect');

    if (stripeRedirect) {
      this.categoryDetails = this.comservice.get('categoryDetails');
      this.myClonedcategoryDetails = this.comservice.get('myClonedcategoryDetails');
      this.MenuDetails = this.comservice.get('MenuDetails');
      this.buyerDetails = this.comservice.get('buyerDetails');
      this.businessDetails = this.comservice.get('businessDetails');

      this.cartFee = this.comservice.get('cartFee');
      this.paymentMethod = this.comservice.get('paymentMethod');
      this.rewardsData = this.comservice.get('rewardWallet');
      this.sourcetype = this.comservice.get('sourcetype');
      this.sourcetypename = this.comservice.get('sourcetypename');
      this.discountInfo = this.comservice.get('discountInfo');
      this.extraJson = this.comservice.get('extraJson');


      this.comservice.remove('businessDetails');
      this.comservice.remove('categoryDetails');
      this.comservice.remove('myClonedcategoryDetails');
      this.comservice.remove('MenuDetails');
      this.comservice.remove('buyerDetails');
      this.comservice.remove('stripeRedirect');

      this.comservice.remove('cartFee');
      this.comservice.remove('paymentMethod');
      this.comservice.remove('rewardWallet');
      this.comservice.remove('sourcetype');
      this.comservice.remove('sourcetypename');
      this.comservice.remove('discountInfo');
      this.comservice.remove('extraJson');

      this.snackBar.open('Not payment successfully, please choose another payment method', this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    } else {
      this.categoryDetails = this.comservice.getService('categoryDetails');
      this.myClonedcategoryDetails = this.comservice.getService('myClonedcategoryDetails');
      this.MenuDetails = this.comservice.getService('MenuDetails');
      this.businessDetails = this.comservice.getService('businessDetails');

    }


    let bdetails;
    bdetails = this.comservice.getService('businessDetails');

    if (bdetails === undefined) {
      this.router.navigate(['/'])
      .then(() => {
        location.reload();
      });
    }
    this.spinner.show();

    this.CalTotal();
    let date;
    date = new Date().toLocaleString('en-US', {
      timeZone: this.businessDetails.timezone_code
    });
    this.minDate = new Date(date);
    this.currentTime = new Date(date);
    this.maxDate.setDate(this.minDate.getDate() + 6);

    this.laundryPickupMinDate = new Date(date);
    this.laundryPickupMaxDate.setDate(this.laundryPickupMinDate.getDate() + 30);

    this.businessTime = new Array();
    this.paymentMethod = new Array();
    this.apiservice.getAllCurrecny().subscribe((resp: any) => {
      this.allCurrencyCode = resp.code;
      this.allCurrencySymbol = resp.data;
      this.apiservice.getAllPaymentMethod(this.businessDetails.id).subscribe((response: any) => {
        for (let j = 0; j < response.length; j++) {
          let pflag;
          pflag = false;
          if (this.orderType === '1' && response[j].is_delivery === '1') {
            pflag = true;
          } else if (this.orderType === '2' && response[j].is_pickup === '1') {
            pflag = true;
          }else if (this.orderType === '3') {
            pflag = true;
          }
          if (pflag) {
            let paymentc: any;
            paymentc = new Object();
            paymentc.id = response[j].pid;
            paymentc.name = response[j].name;
            paymentc.displayName = response[j].displayName;
            paymentc.active = false;
            paymentc.credential = response[j].credential;

            if (this.businessDetails.laundrystatus === 1 || this.businessDetails.laundrystatus === '1') {
              if (paymentc.id === '1' || paymentc.id === '2') {
                this.paymentMethod.push(paymentc);
              }
            } else {
              if (paymentc.id === '5') {
                if (this.businessDetails.stripeconnect === '1') {
                  this.paymentMethod.push(paymentc);
                }
              } else {
                this.paymentMethod.push(paymentc);
              }
              if (paymentc.id === '3') {
                this.currencyCode = this.allCurrencyCode[response[j].credential.currency];
                this.paymentType = response[j].credential.ptype;
                this.paypalSandBox = response[j].credential.sandboxcre;
                this.paypalProduction = response[j].credential.productioncre;

                this.paypalSecretSandBox = response[j].credential.sandboxdsecret;
                this.paypalSecretProduction = response[j].credential.productionsecret;
              }

              if (paymentc.id === '5') {
                this.stripePublishKey = response[j].credential.publishkey;
                this.stripeSecretKey = response[j].credential.secretkey;
                this.loadStripe();
              }

              if(paymentc.id === '6'){

                //this.paymentId = paymentc.id;
               
                this.RAZORPAY_KEY_ID = response[j].credential.id;
                this.RAZORPAY_KEY_SECRET = response[j].credential.secret;
  
              }
            }
          }
        }
        this.paymentEnabled = true;

      });
    });
    let userDetails: any;
    userDetails = this.comservice.getFromLocal('user-details');
    this.buyerDetails.id = 0;

    if (userDetails === undefined || userDetails === null || Object.keys(userDetails).length === 0) {
      this.buyerDetails.id = 0;
      this.buyerDetails.name = '';
      this.buyerDetails.last_name = '';
      this.buyerDetails.email = '';
      this.buyerDetails.cel = '';
      this.buyerDetails.notes = '';
      this.buyerDetails.specialaddress = '';
      this.buyerDetails.eircode = '';
      this.businessDetails.ordercountstatus = false;
      this.buyerDetails.tableNumber = '';
    } else {
      this.buyerDetails.id = userDetails.id;
      this.buyerDetails.name = userDetails.name;
      this.buyerDetails.last_name = userDetails.last_name;
      this.buyerDetails.email = userDetails.email;
      this.buyerDetails.cel = userDetails.cel;
      this.buyerDetails.notes = '';
      this.buyerDetails.specialaddress = '';
      this.buyerDetails.eircode = '';
      this.buyerDetails.tableNumber = '';


      this.apiservice.getCheckFirstOffer(this.businessDetails.id, userDetails.id).subscribe((response: any) => {
        if (response === true) {
          this.businessDetails.ordercountstatus = true;
        } else {
          this.businessDetails.ordercountstatus = false;
        }
      });
    }

    this.buyerDetails.delievryaddress = this.Search.search_address;
    this.Search.order_type = this.orderType;

    this.sourcetype = 'web';
    this.sourcetypename = this.deviceService.getDeviceInfo();

    this.discount = 0.00;
    this.tips = 0.00;

    this.CartSubTotal = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.CartSubTotal = parseFloat(this.CartSubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.CartSubTotal = parseFloat(this.CartSubTotal).toFixed(2);
          }
        }
      }
    }


    if (this.businessDetails.open === false) {
      this.disabledASAP = true;
      this.preorder = true;
      this.preOrderText = '2';
      this.chnagePreorder(true);
    }

    if (this.preorderDetails.preorder === true) {
      this.preorder = true;
      this.preOrderText = '2';
      if (Number(this.preorderDetails.preorderMenu) !== 0) {
        this.buyerDetails.preorderDate = '';
        this.buyerDetails.preorderTime = 0;
        this.buyerDetails.preorder = true;
        this.businessTime = new Array();

        for (let m of this.MenuDetails) {
          if (m.id === this.preorderDetails.preorderMenu) {
            this.dateFilter = (d: Date) => {
              if (d !== undefined) {
                const day = (d.getDay() === 0) ? 7 : d.getDay();
                return (m.days.indexOf(day) !== -1);
              }
            };
            this.dateClass = (d: Date) => {
              const day = (d.getDay() === 0) ? 7 : d.getDay();
              return (m.days.indexOf(day) === -1) ? (d.getDate() >= this.minDate.getDate() && d.getDate() <= this.maxDate.getDate()) ? 'example-custom-date-class' : undefined : undefined;
            };
          }
        }

        let d;
        d = new Date(this.preorderDetails.preorderDate);
        d.toLocaleDateString();
        let day;
        day = (d.getDay() === 0) ? 7 : d.getDay();

        let dateb;
        let now;
        dateb = new Date().toLocaleString('en-US', {
          timeZone: this.businessDetails.timezone_code
        });
        now = new Date(dateb);
        if (day === now.getDay()) {
          let cStart, cStop;
          cStart = now.getHours() + ':' + now.getMinutes();
          cStop = this.preorderDetails.preorderTime;

          if (cStart !== '' && cStop !== '') {
            let tStart: any;
            tStart = this.parseTime(cStart);
            let tStop: any;
            tStop = this.parseTime(cStop);

            let value;
            value = (tStop - tStart) / (1000 * 60);
            if (value < 10) {
              this.preorderDetails.preorderTime = 0;
              this.preOrderTime = this.preorderDetails.preorderTime;
              this.showPreorderTimeChange = true;
            }
          }
        } else {
          this.preOrderTime = this.preorderDetails.preorderTime;
        }
        this.onValueChange('default', this.preorderDetails.preorderDate);
      }
    } else {
      this.preOrderText = '1';
      this.preorder = false;
    }

    if (this.businessDetails.laundrystatus === 1 || this.businessDetails.laundrystatus === '1') {
      let laundrySchedule;
      laundrySchedule = JSON.parse(this.businessDetails.laundryschedule);
      let laundryScheduleactiveDays: any;
      laundryScheduleactiveDays = [];
      for (let lday of laundrySchedule) {
        if (lday.status) {
          laundryScheduleactiveDays.push(lday.id);
        }
      }

      this.laundryPickupDateFilter = (d: Date): boolean => {

        const day = (d.getDay() === 0) ? 7 : d.getDay();
        return (laundryScheduleactiveDays.indexOf(day) !== -1);
      };
      this.laundryPickupDateClass = (d: Date) => {
        const day = (d.getDay() === 0) ? 7 : d.getDay();
        return (laundryScheduleactiveDays.indexOf(day) === -1) ? (d.getDate() >= this.minDate.getDate() && d.getDate() <= this.maxDate.getDate()) ? 'example-custom-date-class' : undefined : undefined;
      };
    }

    this.spinner.hide();
  }
  parseTime(cTime: any) {
    if (cTime === '') {
      return null;
    }
    let d;
    d = new Date();
    let time;
    time = cTime.match(/(\d+)(:(\d\d))?\s*(p?)/);
    d.setHours( parseInt(time[1], 10) + ( ( parseInt(time[1], 10) < 12 && time[4] ) ? 12 : 0) );
    d.setMinutes( parseInt(time[3], 10) || 0 );
    d.setSeconds(0, 0);
    return d;
  }
  onValueChange(type: string, event: any) {
    let d: any;
    if (type === 'change') {
      d = new Date(event);
      this.preOrderTime = 0;
      this.showPreorderTimeChange = false;
    } else if (type === 'default') {
      d = new Date(event);
      this.preOrderTime = this.preorderDetails.preorderTime;
    }

    this.buyerDetails.preorderDate = d.toLocaleDateString();
    let day;
    day = (d.getDay() === 0) ? 7 : d.getDay();
    if (Number(this.preorderDetails.preorderMenu) === 0) {
      this.businessDaysObject = this.businessDetails.schedule.sdays;
      let open;
      let open2;
      let close;
      let close2;
      open = this.businessDaysObject[day].opens.hour + ':' + this.businessDaysObject[day].opens.minute;
      close = this.businessDaysObject[day].closes.hour + ':' + this.businessDaysObject[day].closes.minute;
      open2 = this.businessDaysObject[day].opens2.hour + ':' + this.businessDaysObject[day].opens2.minute;
      close2 = this.businessDaysObject[day].closes2.hour + ':' + this.businessDaysObject[day].closes2.minute;

      this.businessTime = new Array();
      const minutesToAdjust = 15;
      const millisecondsPerMinute = 60000;
      let now: any;
      let nowTime: any;
      let userTime1: any;
      let userTime2: any;
      let userTime3: any;
      let userTime4: any;
      let date: any;
      date = new Date().toLocaleString('en-US', {
        timeZone: this.businessDetails.timezone_code
      });
      now = new Date(date);
      let mins;
      mins = now.getMinutes();
      let quarterHours;
      quarterHours = Math.round(mins / 15);
      if (quarterHours === 4) {
          now.setHours(now.getHours() + 1);
      }
      let rounded;
      rounded = (quarterHours * 15) % 60;
      now.setMinutes(rounded);
      nowTime = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes());
      userTime1 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + open);
      userTime2 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + close);
      userTime3 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + open2);
      userTime4 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + close2);

      userTime2 = new Date(userTime2.valueOf() - (minutesToAdjust * millisecondsPerMinute));
      userTime4 = new Date(userTime4.valueOf() - (minutesToAdjust * millisecondsPerMinute));

      if (day === now.getDay()) {
        if (nowTime.getTime() < userTime1.getTime()) {
          let time;
          time = userTime1;
          while (time.getTime() <= userTime2.getTime()) {
            let hour;
            hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
            let minute;
            minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
            this.businessTime.push(hour + ':' + minute);
            time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          }
          time = userTime3;
          while (time.getTime() <= userTime4.getTime()) {
            let hour;
            hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
            let minute;
            minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
            this.businessTime.push(hour + ':' + minute);
            time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          }
        } else if (nowTime.getTime() >= userTime1.getTime() && nowTime.getTime() <= userTime2.getTime()) {
          let time;
          time = nowTime;
          time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          while (time.getTime() <= userTime2.getTime()) {
            let hour;
            hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
            let minute;
            minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
            this.businessTime.push(hour + ':' + minute);
            time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          }
          time = userTime3;
          while (time.getTime() <= userTime4.getTime()) {
            let hour;
            hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
            let minute;
            minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
            this.businessTime.push(hour + ':' + minute);
            time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          }
        } else if (nowTime.getTime() < userTime3.getTime()) {
          let time;
          time = userTime3;
          while (time.getTime() <= userTime4.getTime()) {
            let hour;
            hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
            let minute;
            minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
            this.businessTime.push(hour + ':' + minute);
            time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          }
        } else if (nowTime.getTime() >= userTime3.getTime() && nowTime.getTime() <= userTime4.getTime()) {
          let time;
          time = nowTime;
          time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          while (time.getTime() <= userTime4.getTime()) {
            let hour;
            hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
            let minute;
            minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
            this.businessTime.push(hour + ':' + minute);
            time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
          }
        }
      } else {
        let time;
        time = userTime1;
        while (time.getTime() <= userTime2.getTime()) {
          let hour;
          hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
          let minute;
          minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
          this.businessTime.push(hour + ':' + minute);
          time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
        }
        time = userTime3;
        while (time.getTime() <= userTime4.getTime()) {
          let hour;
          hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
          let minute;
          minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
          this.businessTime.push(hour + ':' + minute);
          time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
        }
      }
    } else {
      let menuSchedule;
      menuSchedule = this.preorderDetails.preorderMenuSchedule;
      for (let ms of menuSchedule) {
        if (ms.id === day) {
          let menuObject;
          menuObject = ms.times;

          this.menuTime = new Array();
          const minutesToAdjust = 15;
          const millisecondsPerMinute = 60000;

          for (let ts of menuObject) {
            let open;
            let close;
            open = ts.opens.hour + ':' + ts.opens.minute;
            close = ts.closes.hour + ':' + ts.closes.minute;

            let now;
            let nowTime;
            let userTime1, userTime2;
            let date;
            date = new Date().toLocaleString('en-US', {
              timeZone: this.businessDetails.timezone_code
            });
            now = new Date(date);
            let mins;
            mins = now.getMinutes();
            let quarterHours;
            quarterHours = Math.round(mins / 15);
            if (quarterHours === 4) {
                now.setHours(now.getHours() + 1);
            }
            let rounded;
            rounded = (quarterHours * 15) % 60;
            now.setMinutes(rounded);
            nowTime = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes());
            userTime1 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + open);
            userTime2 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + close);
            userTime2 = new Date(userTime2.valueOf() - (minutesToAdjust * millisecondsPerMinute));

            if (day === now.getDay()) {
              if (nowTime.getTime() < userTime1.getTime()) {
                let time;
                time = userTime1;
                while (time.getTime() <= userTime2.getTime()) {
                  let hour;
                  hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                  let minute;
                  minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                  this.menuTime.push(hour + ':' + minute);
                  time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                }
              } else if (nowTime.getTime() >= userTime1.getTime() && nowTime.getTime() <= userTime2.getTime()) {
                let time;
                time = nowTime;
                time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                while (time.getTime() <= userTime2.getTime()) {
                  let hour;
                  hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                  let minute;
                  minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                  this.menuTime.push(hour + ':' + minute);
                  time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                }
              }
            } else {
              userTime1 = new Date(userTime1.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              let time;
              time = userTime1;
              while (time.getTime() <= userTime2.getTime()) {
                let hour;
                hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                let minute;
                minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                this.menuTime.push(hour + ':' + minute);
                time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              }
            }
          }
        }
      }
      this.businessTime = this.menuTime;
      this.buyerDetails.preorderTime = this.preOrderTime;
    }
  }
  chnagePreorder(val: any) {
    this.buyerDetails.preorderDate = '';
    this.buyerDetails.preorderTime = 0;
    this.buyerDetails.preorder = val;
    this.preorder = val;
    this.businessTime = new Array();
  }
  onTimeChange(event: any) {
    let val: any;
    val = event.target.value;
    this.showPreorderTimeChange = false;
    this.buyerDetails.preorderTime = val;
  }


  // Laundry Settings
  onLaundryPickupValueChange(type: string, event: any) {
    this.laundryService.pickupTime = '';
    this.laundryService.deliveryTime = '';
    let d: any;
    d = new Date(event);
    this.laundryService.pickupDate = d.toLocaleDateString();
    let day: any;
    day = (d.getDay() === 0) ? 7 : d.getDay();
    this.laundryPickupMenuTime = new Array();

    let PickupMenuTime: any;
    PickupMenuTime = [];
    let laundrySchedule: any;
    laundrySchedule = JSON.parse(this.businessDetails.laundryschedule);
    for (let lday of laundrySchedule) {
      if (lday.id === day) {
        let pickupTimes: any;
        pickupTimes = lday.times;

        const minutesToAdjust = this.businessDetails.slottime;
        const millisecondsPerMinute = 60000;
        for (let ts of pickupTimes) {
          let open;
          let close;
          open = ts.opens.hour + ':' + ts.opens.minute;
          close = ts.closes.hour + ':' + ts.closes.minute;
          let now: any;
          let nowTime: any;
          let userTime1: any;
          let userTime2: any;
          let date: any;
          date = new Date().toLocaleString('en-US', {
            timeZone: this.businessDetails.timezone_code
          });
          now = new Date(date);
          let mins;
          mins = now.getMinutes();
          let quarterHours;
          quarterHours = Math.round(mins / 15);
          if (quarterHours === 4) {
              now.setHours(now.getHours() + 1);
          }
          let rounded;
          rounded = (quarterHours * 15) % 60;
          now.setMinutes(rounded);
          nowTime = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes());
          userTime1 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + open);
          userTime2 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + close);
          userTime2 = new Date(userTime2.valueOf() - (minutesToAdjust * millisecondsPerMinute));

          if (day === now.getDay()) {
            if (nowTime.getTime() < userTime1.getTime()) {
              let time;
              time = userTime1;
              while (time.getTime() <= userTime2.getTime()) {
                let hour;
                hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                let minute;
                minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                PickupMenuTime.push(hour + ':' + minute);
                time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              }
            } else if (nowTime.getTime() >= userTime1.getTime() && nowTime.getTime() <= userTime2.getTime()) {
              let time;
              time = nowTime;
              time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              while (time.getTime() <= userTime2.getTime()) {
                let hour;
                hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                let minute;
                minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                PickupMenuTime.push(hour + ':' + minute);
                time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              }
            }
          } else {
            userTime1 = new Date(userTime1.valueOf() + (minutesToAdjust * millisecondsPerMinute));
            let time;
            time = userTime1;
            while (time.getTime() <= userTime2.getTime()) {
              let hour;
              hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
              let minute;
              minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
              PickupMenuTime.push(hour + ':' + minute);
              time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
            }
          }
        }
        this.laundryPickupMenuTime = PickupMenuTime;
      }
    }
  }
  onLaundryPickupTimeChange() {
    const hoursToAdjust = this.businessDetails.collectiontime;
    let pickupDate: any;
    pickupDate = this.laundryService.pickupDate.split('/');
    let d: any;
    d = new Date(pickupDate[1] + '/' + pickupDate[0] + '/' + pickupDate[2] + ' ' + this.laundryService.pickupTime);
    let prevday: any;
    prevday = (d.getDay() === 0) ? 7 : d.getDay();
    let newTime: any;
    newTime = new Date(d.valueOf() + (hoursToAdjust * 60 * 60000));
    let newday: any;
    newday = (newTime.getDay() === 0) ? 7 : newTime.getDay();
    let laundrySchedule: any;
    laundrySchedule = JSON.parse(this.businessDetails.laundryschedule);
    let i: any;
    i  = prevday + 1;
    for ( ; i <= newday; i++) {
      for (let lday of laundrySchedule) {
        if (lday.id === i && lday.status === false) {
          newTime = new Date(newTime.valueOf() + (24 * 60 * 60000));
          newday = (newTime.getDay() === 0) ? 7 : newTime.getDay();
        }
      }
    }
    this.laundryDeliveryMinDate = newTime;
    this.laundryDeliveryMaxDate = new Date();
    let currentMonth: any;
    currentMonth = this.laundryDeliveryMaxDate.getMonth() + 1;
    let minDateMonth: any;
    minDateMonth = this.laundryDeliveryMinDate.getMonth() + 1;
    let daysAdd: any;
    if (currentMonth === minDateMonth) {
      daysAdd = 30;
    } else if (currentMonth > minDateMonth) {
      minDateMonth = currentMonth + minDateMonth;
      daysAdd = ((minDateMonth - currentMonth) * 30) + 30;
    } else {
      daysAdd = (minDateMonth - currentMonth) * 30;
    }
    this.laundryDeliveryMaxDate.setDate(this.laundryDeliveryMinDate.getDate() + daysAdd);

    d = newTime;
    let laundryScheduleactiveDays: any;
    laundryScheduleactiveDays = [];
    for (let lday of laundrySchedule) {
      if (lday.status) {
        laundryScheduleactiveDays.push(lday.id);
      }
    }

    this.laundryDeliveryDateFilter = (d: Date): boolean => {
      const day = (d.getDay() === 0) ? 7 : d.getDay();
      return (laundryScheduleactiveDays.indexOf(day) !== -1);
    };
    this.laundryDeliveryDateClass = (d: Date) => {
      const day = (d.getDay() === 0) ? 7 : d.getDay();
      return (laundryScheduleactiveDays.indexOf(day) === -1) ? (d.getDate() >= this.minDate.getDate() && d.getDate() <= this.maxDate.getDate()) ? 'example-custom-date-class' : undefined : undefined;
    };
  }
  onDeliveryValueChange(type: string, event: any) {
    this.laundryService.deliveryTime = '';
    if (this.laundryService.pickupDate === '' || this.laundryService.pickupTime === '') {
      this.snackBar.open('select pickup date & time', this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    let d: any;
    d = new Date(event);

    this.laundryService.deliveryDate = d.toLocaleDateString();
    let day: any;
    day = (d.getDay() === 0) ? 7 : d.getDay();
    this.laundryDeliveryMenuTime = new Array();

    let deliveryMenuTime: any;
    deliveryMenuTime = [];

    let laundrySchedule: any;
    laundrySchedule = JSON.parse(this.businessDetails.laundryschedule);
    for (let lday of laundrySchedule) {
      if (lday.id === day) {
        let deliveryTimes;
        deliveryTimes = lday.times;
        const minutesToAdjust = this.businessDetails.slottime;
        const millisecondsPerMinute = 60000;
        for (let ts of deliveryTimes) {
          let open;
          let close;
          open = ts.opens.hour + ':' + ts.opens.minute;
          close = ts.closes.hour + ':' + ts.closes.minute;
          let now: any;
          let nowTime: any;
          let userTime1: any;
          let userTime2: any;

          now = this.laundryDeliveryMinDate;
          let mins;
          mins = now.getMinutes();
          let quarterHours;
          quarterHours = Math.round(mins / 15);
          if (quarterHours === 4) {
              now.setHours(now.getHours() + 1);
          }
          let rounded;
          rounded = (quarterHours * 15) % 60;
          now.setMinutes(rounded);
          nowTime = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes());
          userTime1 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + open);
          userTime2 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + close);
          userTime2 = new Date(userTime2.valueOf() - (minutesToAdjust * millisecondsPerMinute));
          let nowDay;
          nowDay = (now.getDay() === 0) ? 7 : now.getDay();
          if (day === nowDay) {
            if (nowTime.getTime() < userTime1.getTime()) {
              let time;
              time = userTime1;
              while (time.getTime() <= userTime2.getTime()) {
                let hour;
                hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                let minute;
                minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                deliveryMenuTime.push(hour + ':' + minute);
                time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              }
            } else if (nowTime.getTime() >= userTime1.getTime() && nowTime.getTime() <= userTime2.getTime()) {
              let time;
              time = nowTime;
              time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              while (time.getTime() <= userTime2.getTime()) {
                let hour;
                hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                let minute;
                minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                deliveryMenuTime.push(hour + ':' + minute);
                time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
              }
            }
          } else {
            userTime1 = new Date(userTime1.valueOf() + (minutesToAdjust * millisecondsPerMinute));
            let time;
            time = userTime1;
            while (time.getTime() <= userTime2.getTime()) {
              let hour;
              hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
              let minute;
              minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
              deliveryMenuTime.push(hour + ':' + minute);
              time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
            }
          }
        }
        this.laundryDeliveryMenuTime = deliveryMenuTime;
      }
    }
  }
  // Laundry Settings

  TipsCal() {
    if (this.texttips === undefined || this.texttips === '') {
      this.tips = 0.00;
      this.tips = parseFloat(this.tips).toFixed(2);
      this.CalTotal();
    } else {
      this.tips = parseFloat(this.texttips).toFixed(2);
      this.CalTotal();
    }
  }
  showOptions(event: any) {
    this.tipspercentage = 0;
    this.tips = 0.00;
    this.tips = parseFloat(this.tips).toFixed(2);
    this.CalTotal();
  }
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    return value + '%';
  }
  tipsCalCustom(event: any) {
    this.tips = ((event.value * this.SubTotal) / 100);
    this.tips = parseFloat(this.tips).toFixed(2);
    this.CalTotal();
  }
  CalTotal() {
    let SubTotalDiscount: any;
    SubTotalDiscount = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            SubTotalDiscount = parseFloat(SubTotalDiscount) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            SubTotalDiscount = parseFloat(SubTotalDiscount).toFixed(2);
          }
        }
      }
    }

    this.apiservice.getOfferCheck(this.businessDetails.id, SubTotalDiscount, this.orderType).subscribe((response: any) => {
      if (response.status === true) {
        this.discountInfo.discounttype = 'offer';
        if (response.d_type === '1') {
          this.discount = response.price;
          this.CalDiscount();
        } else {
          let percent;
          percent = Number(response.percent) * 0.01;
          this.discount = parseFloat(SubTotalDiscount) * percent;
          this.discount = parseFloat(this.discount).toFixed(2);
          this.CalDiscount();
        }
      } else {
        this.CalDiscount();
      }
    });
  }
  CalDiscount() {
    this.cartFee = new Array();
    this.SubTotal = 0.00;

    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.SubTotal = parseFloat(this.SubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.SubTotal = parseFloat(this.SubTotal).toFixed(2);
          }
        }
      }
      let firstOffer: any;
      firstOffer = 0.00;
      if (this.businessDetails.firstorderstatus === '1' && this.businessDetails.ordercountstatus) {
        if (parseFloat(this.SubTotal) >= parseFloat(this.businessDetails.firstordervalue)) {
          firstOffer = parseFloat(this.SubTotal) * this.businessDetails.firstorderoffer * 0.01;
          firstOffer = parseFloat(firstOffer).toFixed(2);
        }
      }
      this.CartDish[i].details.delivery = this.deliveryDetails.delivery;
      if (this.businessDetails.freedeliverystatus === '1') {
        if (parseFloat(this.SubTotal) >= parseFloat(this.businessDetails.freedeliveryvalue)) {
          this.CartDish[i].details.delivery = 0.00;
        }
      }
      if (this.orderType === '2') {
        this.CartDish[i].details.delivery = 0.00;
      }


      let extraminimum: any;
      extraminimum = 0.00;
      if (this.businessDetails.requiredminimumstatus === '1') {
        if (parseFloat(this.SubTotal) < parseFloat(this.businessDetails.minimumfee)) {
          extraminimum = parseFloat(this.businessDetails.minimumfee) - parseFloat(this.SubTotal);
          extraminimum = parseFloat(extraminimum).toFixed(2);
        }
      }

      let subtotal: any;
      subtotal = parseFloat(this.SubTotal) - parseFloat(this.discount);
      subtotal = parseFloat(subtotal).toFixed(2);

      let taxprice: any;
      taxprice = parseFloat(subtotal) * Number(this.CartDish[i].details.tax * 0.01);
      taxprice = parseFloat(taxprice).toFixed(2);

      let servicefeeprice: any;
      servicefeeprice = parseFloat(subtotal) + parseFloat(this.CartDish[i].details.delivery);
      servicefeeprice = servicefeeprice * this.CartDish[i].details.servicefee * 0.01;
      servicefeeprice = parseFloat(servicefeeprice).toFixed(2);

      let total: any;
      if (this.CartDish[i].details.taxtype === '0') {
        total = parseFloat(this.CartSubTotal) - parseFloat(this.discount) + parseFloat(this.CartDish[i].details.delivery) + parseFloat(taxprice) + parseFloat(servicefeeprice) + parseFloat(this.tips);
        total = parseFloat(total).toFixed(2);
      } else {
        total = parseFloat(this.CartSubTotal) - parseFloat(this.discount) + parseFloat(this.CartDish[i].details.delivery) + parseFloat(servicefeeprice) + parseFloat(this.tips);
        total = parseFloat(total).toFixed(2);
      }


      if (this.businessDetails.requiredminimumstatus === '1') {
        total = parseFloat(total) + parseFloat(extraminimum);
        total = parseFloat(total).toFixed(2);
      }

      if (this.businessDetails.firstorderstatus === '1' && this.businessDetails.ordercountstatus) {
        total = parseFloat(total) - parseFloat(firstOffer);
        total = parseFloat(total).toFixed(2);
      }

      let fee: any;
      fee = {
        'id': this.CartDish[i].id,
        'name': this.CartDish[i].details.name,
        'subtotal': this.SubTotal,
        'tax': this.CartDish[i].details.tax,
        'taxtype': this.CartDish[i].details.taxtype,
        'taxprice': taxprice,
        'deliverycost': this.CartDish[i].details.delivery,
        'extraminimum': extraminimum,
        'firstoffer': firstOffer,
        'servicefee': this.CartDish[i].details.servicefee,
        'servicefeeprice': servicefeeprice,
        'discount': this.discount,
        'tips': this.tips,
        'total': total
      };
      this.paypalTotal = total;
      this.cartFee.push(fee);

      let uDetails: any;
      uDetails = this.comservice.getFromLocal('user-details');
      if (uDetails !== undefined && uDetails !== null && Object.keys(uDetails).length !== 0 && this.businessDetails.rewardstatus === '1' && this.businessDetails.rewards === '1' && this.businessDetails.rewardprice > 0) {
        this.getRewards(uDetails.id, this.businessDetails.id, (res: any, event: any) => {
          event.rewardsData = res;
          if (event.rewardsData.totalPoint > 0) {
            event.rewardsData.totalPrice = event.rewardsData.totalPoint / event.businessDetails.rewardprice;
            event.rewardsData.checked = true;
            if (event.rewardsData.totalPrice) {
              if (event.cartFee[0].total >= event.rewardsData.totalPrice) {
                event.rewardsData.deductPrice = event.rewardsData.totalPrice;
                let deductPrice: any;
                deductPrice = Number(event.rewardsData.deductPrice);
                deductPrice = parseFloat(deductPrice).toFixed(2);
                event.rewardsData.deductPrice = deductPrice;
                if (event.cartFee[0].total == event.rewardsData.totalPrice) {
                  event.rewardsData.checkedWalllet = true;
                } else {
                  event.rewardsData.amountPrice = event.cartFee[0].total - event.rewardsData.totalPrice;
                  event.rewardsData.checkedWalllet = false;
                  let amountPrice: any;
                  amountPrice = Number(event.rewardsData.amountPrice);
                  amountPrice = parseFloat(amountPrice).toFixed(2);
                  event.rewardsData.amountPrice = amountPrice;
                  event.paypalTotal = amountPrice;
                }
              } else {
                event.rewardsData.checkedWalllet = true;
                event.rewardsData.deductPrice = event.cartFee[0].total;
              }
            }
          }
        });
      }
    }
  }
  CheckDiscount() {
    if (this.discountInfo.discounttext.length === 0) {
      return false;
    }
    let SubTotalDiscount: any;
    SubTotalDiscount = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            SubTotalDiscount = parseFloat(SubTotalDiscount) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            SubTotalDiscount = parseFloat(SubTotalDiscount).toFixed(2);
          }
        }
      }
    }

    this.apiservice.getDiscountCheck(this.businessDetails.id, SubTotalDiscount, this.discountInfo.discounttext).subscribe((response: any) => {
      if (response.status === true) {
        this.discountInfo.discounttype = 'code';
        this.discountInfo.discountid = response.id;
        if (response.d_type === '1') {
          this.discount = response.price;
          this.CalDiscount();
        } else {
          this.discount = parseFloat(SubTotalDiscount) * Number(response.percent * 0.01);
          this.discount = parseFloat(this.discount).toFixed(2);
          this.CalDiscount();
        }
      } else {
        this.snackBar.open(this.language.DISCOUNT_CODE_NOT_VALID, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }

  checkValidation() {
    if (this.buyerDetails.name === undefined || this.buyerDetails.name === '') {
      this.snackBar.open(this.language.ENTER_FIRST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.buyername.nativeElement.focus();
      }
      return false;
    }
    if (this.buyerDetails.last_name === undefined || this.buyerDetails.last_name === '') {
      this.snackBar.open(this.language.ENTER_YOUR_LAST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.buyerlastname.nativeElement.focus();
      }
      return false;
    }

    if (this.Search.order_type === '1') {
      if (this.buyerDetails.delievryaddress === '') {
        this.snackBar.open(this.language.ENTER_YOUR_ADDRESS, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        if (isPlatformBrowser(this.platformId)) {
          this.buyeraddress.nativeElement.focus();
        }
        return false;
      }
      if (this.buyerDetails.specialaddress === '') {
        this.snackBar.open(this.language.ENTER_YOUR_SPECIAL_ADDRESS, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        if (isPlatformBrowser(this.platformId)) {
          this.bspecialaddress.nativeElement.focus();
        }
        return false;
      }
      if (this.buyerDetails.eircode === '') {
        this.snackBar.open(this.language.ENTER_YOUR_EIRCODE, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        if (isPlatformBrowser(this.platformId)) {
          this.beircode.nativeElement.focus();
        }
        return false;
      }
    }

    if (this.buyerDetails.email === '') {
      this.snackBar.open(this.language.ENTER_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.buyeremail.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.buyerDetails.email.indexOf('@');
    let dotpos;
    dotpos = this.buyerDetails.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.buyerDetails.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.buyeremail.nativeElement.focus();
      }
      return false;
    }

    if (this.buyerDetails.cel === '') {
      this.snackBar.open(this.language.ENTER_YOUR_MOBILE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.buyermobile.nativeElement.focus();
      }
      return false;
    }

    if (this.Search.order_type === '3') {
      if (this.buyerDetails.tableNumber === '') {
        this.snackBar.open(this.language.ENTER_TABLE_NUMBER, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        if (isPlatformBrowser(this.platformId)) {
          this.tablenumber.nativeElement.focus();
        }
        return false;
      }
    }

    if (this.preorder === true) {
      this.buyerDetails.preorder = true;
      if (this.buyerDetails.preorderDate === '') {
        this.snackBar.open(this.language.SELECT_PREORDER_DATE, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
      if (this.buyerDetails.preorderTime === 0) {
        this.snackBar.open(this.language.SELECT_PREORDER_TIME, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
    } else {
      this.buyerDetails.preorder = false;
    }

    if (this.businessDetails.laundrystatus === 1 || this.businessDetails.laundrystatus === '1') {
      if (this.laundryService.pickupDate === '') {
        this.snackBar.open(this.language.SELECT_PICKUP_DATE, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
      if (this.laundryService.pickupTime === '') {
        this.snackBar.open(this.language.SELECT_PICKUP_TIME, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
      if ( this.laundryService.deliveryDate === '') {
        this.snackBar.open(this.language.SELECT_DELIVERY_DATE, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
      if (this.laundryService.deliveryTime === '') {
        this.snackBar.open(this.language.SELECT_DELIVERY_TIME, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
    }
    return true;
  }
  checkReward() {
    if (!this.rewardsData.checked) {
      if (this.rewardsData.checkedWalllet) {
        this.paymentId = 0;
        this.paypalExpress = false;
      } else {
        let amountPrice: any;
        amountPrice = Number(this.rewardsData.amountPrice);
        amountPrice = parseFloat(amountPrice).toFixed(2);
        this.paypalTotal = amountPrice;
      }
    } else {
      let amountPrice;
      amountPrice = this.cartFee[0].total;
      amountPrice = parseFloat(amountPrice).toFixed(2);
      this.paypalTotal = amountPrice;
    }
  }
  SelectPayment(val: any) {
    if (!this.checkValidation()) {
      return false;
    }

    let uDetails;
    uDetails = this.comservice.getFromLocal('user-details');
    if (uDetails !== undefined && uDetails !== null && Object.keys(uDetails).length !== 0 && this.businessDetails.rewardstatus === '1' && this.businessDetails.rewards === '1') {
      if (this.rewardsData.totalPoint > 0) {
        if (this.rewardsData.checkedWalllet === false) {
          let amountPrice: any;
          amountPrice = Number(this.rewardsData.amountPrice);
          amountPrice = parseFloat(amountPrice).toFixed(2);
          this.paypalTotal = amountPrice;
        } else {
          this.rewardsData.checked = false;
        }
      }
    }
    this.paymentId = val.id;
    this.paypalExpress = false;
    let paypalindex;
    for (let i = 0; i < this.paymentMethod.length; i++) {
      if (this.paymentMethod[i].id === '3') {
        paypalindex = i;
      }
      if (this.paymentMethod[i].id === val.id) {
        this.paymentMethod[i].active = true;
        if (this.paymentMethod[i].id === '3') {
          this.spinner.show();
          this.paypalExpress = true;
          if (!this.addScript) {
            let that: any;
            that = this;
            this.addPaypalScript().then(() => {
              this.spinner.hide();
              paypal.Buttons({
                // Set up the transaction
                style: {
                  color:  'blue',
                  shape:  'pill',
                  label:  'pay',
                  height: 40,
                  layout: 'horizontal'
                },
                createOrder: (data: any, actions: any) => {
                  that.paypalLoading = true;
                  return actions.order.create({
                      purchase_units: [{
                          amount: {
                              value: that.paypalTotal
                          }
                      }]
                  });
                },
                onError: (err: any) => {
                  that.paypalLoading = false;
                  console.log(err);
                  // Show an error page here, when an error occurs
                },
                onCancel: (data: any, actions: any) => {
                  that.paypalLoading = false;
                  console.log(data, actions);
                },
                // Finalize the transaction
                onApprove: (data: any, actions: any) => {
                  let postData;
                    postData = {
                      f: 'paypalplaceorder',
                      userId: that.buyerDetails.id,
                      CartDish: JSON.stringify(that.CartDish),
                      cartFee: JSON.stringify(that.cartFee),
                      BuyerDetails: JSON.stringify(that.buyerDetails),
                      deliveryDetails: JSON.stringify(that.deliveryDetails),
                      Search: JSON.stringify(that.Search),
                      rewardWallet: JSON.stringify(that.rewardsData),
                      discountInfo: JSON.stringify(that.discountInfo),
                      txnId: data.orderID
                    };
                    $.post(Globals.API_ENDPOINT_PLACE, postData, (res: any) => {
                      return actions.order.capture().then((details: any) => {
                        // Show a success message to the buyer
                        // console.log('Transaction completed by ' + details.payer.name.given_name + '!');
                        console.log(data, details);
                        let clientId;
                        clientId = (that.paymentType === 'sandbox') ? that.paypalSandBox : that.paypalProduction;
                        let secretkey;
                        secretkey = (that.paymentType === 'sandbox') ? that.paypalSecretSandBox : that.paypalSecretProduction;

                        that.txnId = details.purchase_units[0].payments.captures[0].id; // data.orderID;
                        that.apiservice.paypalPayment(data.orderID, that.paymentType, clientId, secretkey, that.txnId).subscribe((resp: any) => {
                          // console.log(resp);
                          that.checkOtp();
                        });
                      });
                    });
                }
              }).render('#paypal-button-container');
            });
          }
        }
      } else {
        this.paymentMethod[i].active = false;
      }
    }
  }
  addPaypalScript() {
    return new Promise((resolve, reject) => {
      let scripttagElement;
      scripttagElement = document.createElement('script');
      let clientId;
      clientId = (this.paymentType === 'sandbox') ? this.paypalSandBox : this.paypalProduction;
      scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id=' + clientId + '&currency=' + this.currencyCode;
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    });
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  CheckallDetails() {
    if (!this.checkValidation()) {
      return false;
    }
    let flag = false;

    let uDetails;
    uDetails = this.comservice.getFromLocal('user-details');
    if (uDetails !== undefined && uDetails !== null && Object.keys(uDetails).length !== 0 && this.businessDetails.rewardstatus === '1' && this.businessDetails.rewards === '1') {
      if (this.rewardsData.totalPoint > 0) {
        if (this.rewardsData.checkedWalllet && this.rewardsData.checked) {
          flag = true;
        }
      }
    }

    
    if (!this.t_and_c()) {
      return false;
    }

    if (!flag) {
      let counter;
      counter = 0;
      let paymentId;
      for (let i = 0; i < this.paymentMethod.length; i++) {
        if (this.paymentMethod[i].active === true) {
          paymentId = this.paymentMethod[i].id;
          counter++;
        }
      }
      if (counter === 0) {
        this.snackBar.open(this.language.SELECT_PAYMENT_METHOD, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
      this.paymentId = paymentId;
      this.checkOtp();
    } else {
      this.checkOtp();
    }
  }
  openFirstData() {
    for (let i = 0; i < this.paymentMethod.length; i++) {
      if (this.paymentMethod[i].id === '4') {
        this.apiservice.checkFirstData(this.paymentMethod[i].credential.storename, this.paymentMethod[i].credential.sharedSecret, this.paymentMethod[i].credential.currency, this.paypalTotal, this.paymentMethod[i].credential.timezone).subscribe((response: any) => {
          const dialogRefFirstData = this.dialog.open(FirstdataComponent, {
            width: '900px',
            data: {
              business: this.businessDetails,
              successurl: Globals.API_ENDPOINT_FIRSTDAT_PAYMENT_SUCCESS,
              errorurl: Globals.API_ENDPOINT_FIRSTDAT_PAYMENT_ERROR,
              timezone: this.paymentMethod[i].credential.timezone,
              txndatetime: response.datetime,
              hash : response.hash,
              storename : this.paymentMethod[i].credential.storename,
              chargetotal: this.paypalTotal,
              currency: this.paymentMethod[i].credential.currency,
            },
            disableClose: true
          });

          dialogRefFirstData.afterClosed().subscribe(result => {
            if (result.status === true) {
              if (result.firstdata === '0') {
                this.paymentMethod[i].active = false;
                this.paymentId = 0;
                this.snackBar.open(this.language.TRANSACTION_FAILED_TRY_AGAIN, this.language.TOAST_OK, {
                  duration: 10000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                });
                return false;
              } else  if (result.firstdata === '1') {
                this.txnId = result.ipgTransactionId;
                this.PlaceOrder(this.comservice, this.router, this);
              }
            } else {
              this.paymentMethod[i].active = false;
              this.paymentId = 0;
              this.snackBar.open(this.language.CHOOSE_OTHER_PAYMENT_GATEWAY, this.language.TOAST_OK, {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
              });
            }
          });
        });
      }
    }
  }
  checkOtp() {
    if (this.businessDetails.smsstatus === '1') {
      if (this.paypalExpress === true) {
        this.paypalLoading = false;
      } else {
        this.spinner.hide();
      }
      this.apiservice.getOtp(this.buyerDetails.cel, this.businessDetails.id).subscribe((response: any) => {
        if (response === 0) {
          this.snackBar.open(this.language.ENTER_VALID_NUMBER_WITH_COUNTRY_CODE, this.language.TOAST_OK, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          return false;
        }
        this.otpverified = response;
        this.openOtp();
      });
    } else {
      this.prePlaceOrder();
    }
  }
  openOtp(): void {
    const dialogRef = this.dialog.open(OtpComponent, {
      width: '500px',
      data: {
        otp: this.otpverified
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.prePlaceOrder();
      }
    });
  }
  prePlaceOrder() {
    if (this.businessDetails.laundrystatus === 1 || this.businessDetails.laundrystatus === '1') {
      this.extraJson.laundryService = this.laundryService;
    }
    if (this.paymentId === '3') {
      this.paypalLoading = true;
      this.PlaceOrder(this.comservice, this.router, this);
    } else if (this.paymentId === '4') {
      this.CalDiscount();
      this.openFirstData();
    } else if (this.paymentId === '5') {
      this.CalDiscount();
      this.stripePay();
    } else {
      this.PlaceOrder(this.comservice, this.router, this);
    }
  }

  PlaceOrder(comservice: any, router: any, event: any) {

    if(this.paymentId == 6){
      let jsondata = {
        "amount": this.cartFee[0].total,
        "currency": this.businessDetails.currency_code,
        "RAZORPAY_KEY_ID": this.RAZORPAY_KEY_ID,
        "RAZORPAY_KEY_SECRET": this.RAZORPAY_KEY_SECRET,
      }

      this.apiservice.CreateRzpayOrder(jsondata).subscribe((response: any) => {
        if(response.success == true){
          this.payWithRazorpay(response.data.id);

        }else{
         
           this.snackBar.open(response.message, this.language.TOAST_OK, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
  
      });

    }else{
      if (this.paymentId === '3') {
        if (this.paypalLoading === false) {
          this.paypalLoading = true;
        }
      } else {
        this.spinner.show();
      }
      this.buyerDetails.name = this.buyerDetails.name.replace(/'/g, "");
      this.buyerDetails.name = this.buyerDetails.name.replace(/\"/g, "");
  
      this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/'/g, "");
      this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/\"/g, "");
  
      this.buyerDetails.notes = this.buyerDetails.notes.replace(/'/g, "");
      this.buyerDetails.notes = this.buyerDetails.notes.replace(/\"/g, "");
  
  
      this.postData = {
        f: 'placeorder',
        langId: 1,
        userId: this.buyerDetails.id,
        CartDish: JSON.stringify(this.CartDish),
        cartFee: JSON.stringify(this.cartFee),
        BuyerDetails: JSON.stringify(this.buyerDetails),
        deliveryDetails: JSON.stringify(this.deliveryDetails),
        Search: JSON.stringify(this.Search),
        paymentMethod: JSON.stringify(this.paymentMethod),
        rewardWallet: JSON.stringify(this.rewardsData),
        sourcetype: this.sourcetype,
        sourcetypename: JSON.stringify(this.sourcetypename),
        discountInfo: JSON.stringify(this.discountInfo),
        txnId: this.txnId,
        extraJson: JSON.stringify(this.extraJson)
      };
  
      $.post(Globals.API_ENDPOINT_PLACE, this.postData, (res: any) => {
        this.orderId = res.placeorder[0];
        comservice.setService('orderId', this.orderId);
        if (event.paymentId === '3') {
          event.paypalLoading = false;
        } else {
          event.spinner.hide();
        }
        router.navigate(['/confirmation']);
      });
    }

  }

  payWithRazorpay(order_id : any){
    const options = {
      "key": this.RAZORPAY_KEY_ID,
      "amount": (this.cartFee[0].total * 100),
      "description": 'Order',
      "image":this.businessDetails.logo,
      "order_id": order_id,//Order ID generated in Step 1
      "currency": this.businessDetails.currency_code,
      "handler": function (response: any) {
        var event = new CustomEvent("payment.success",
          {
            detail: response,
            bubbles: true,
            cancelable: true
          }
        );
        window.dispatchEvent(event);
      },
      "prefill": {
        "name": this.buyerDetails.name,
        "email": this.buyerDetails.email,
        "contact": this.buyerDetails.cel
      },
      "notes": {
        "address": this.buyerDetails.delievryaddress
      },
      "theme": {
        "color": "#3399cc"
      }
    };

    var rzp = new Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', function (response: any) {
      //this.message = "Payment Failed";
      // Todo - store this information in the server
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
      //this.error = response.error.reason;
          alert("Payment failed please try again after some time");
      }
    );
       
  }
  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {

      this.spinner.show();
      this.buyerDetails.name = this.buyerDetails.name.replace(/'/g, "");
      this.buyerDetails.name = this.buyerDetails.name.replace(/\"/g, "");
  
      this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/'/g, "");
      this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/\"/g, "");
  
      this.buyerDetails.notes = this.buyerDetails.notes.replace(/'/g, "");
      this.buyerDetails.notes = this.buyerDetails.notes.replace(/\"/g, "");
  
      this.postData = {
        f: 'placeorder',
        langId: 1,
        userId: this.buyerDetails.id,
        CartDish: JSON.stringify(this.CartDish),
        cartFee: JSON.stringify(this.cartFee),
        BuyerDetails: JSON.stringify(this.buyerDetails),
        deliveryDetails: JSON.stringify(this.deliveryDetails),
        Search: JSON.stringify(this.Search),
        paymentMethod: JSON.stringify(this.paymentMethod),
        rewardWallet: JSON.stringify(this.rewardsData),
        sourcetype: this.sourcetype,
        sourcetypename: JSON.stringify(this.sourcetypename),
        discountInfo: JSON.stringify(this.discountInfo),
        txnId: event.detail.razorpay_payment_id,
        extraJson: JSON.stringify(this.extraJson)
      };
  
      $.post(Globals.API_ENDPOINT_PLACE, this.postData, (res: any) => {
        this.orderId = res.placeorder[0];
        this.comservice.setService('orderId', this.orderId);
          this.spinner.hide();
        this.router.navigate(['/confirmation']);
      });

  }

  getRewards(userId: any, businessId: any, callback: any) {
    this.apiservice.getRewardsbyBusinessId(userId, businessId).subscribe((response: any) => {
      if (callback) {
        callback(response, this);
      }
    });
  }
  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      let s;
      s = window.document.createElement('script');
      s.id = 'stripe-script';
      s.type = 'text/javascript';
      s.src = 'https://js.stripe.com/v3/';
      s.onload = () => {
      };
      window.document.body.appendChild(s);
    }
  }
  stripePay() {
    this.spinner.show();
    let that = this;
    let amount: any;
    amount = parseFloat(this.paypalTotal).toFixed(2);

    let stripe: any;
    stripe = Stripe(this.stripePublishKey);

    fetch(Globals.API_ENDPOINT_STRIPE, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({
        success: Globals.API_ENDPOINT_STRIPE_SUCCESS,
        error: Globals.API_ENDPOINT_STRIPE_ERROR,
        stripeSecretKey: this.stripeSecretKey,
        totalAmount: amount * 100,
        currencyCode: this.businessDetails.currency_code,
        bname: this.businessDetails.name
      }),
    })
    .then(function (response) {
      return response.json();
    })
    .then(function (response) {
      if (response.error) {
        that.spinner.hide();
        alert(response.error.message);
      } else {
        that.comservice.set('businessDetails', that.businessDetails);
        that.comservice.set('categoryDetails', that.categoryDetails);
        that.comservice.set('myClonedcategoryDetails', that.myClonedcategoryDetails);
        that.comservice.set('MenuDetails', that.MenuDetails);
        that.comservice.set('buyerDetails', that.buyerDetails);
        that.comservice.set('cartFee', that.cartFee);
        that.comservice.set('paymentMethod', that.paymentMethod);
        that.comservice.set('rewardWallet', that.rewardsData);
        that.comservice.set('sourcetype', that.sourcetype);
        that.comservice.set('sourcetypename', that.sourcetypename);
        that.comservice.set('discountInfo', that.discountInfo);
        that.comservice.set('extraJson', that.extraJson);
        that.comservice.set('stripeRedirect', response);

        /* this part not remove from localstorage after su occer eroor occured */
        that.comservice.set('cartDetails', that.CartDish);
        that.comservice.set('deliveryDetails', that.deliveryDetails);
        that.comservice.set('Search', that.Search);
        that.comservice.set('preorderDetails', that.Search);
        /* this part not remove from localstorage after su occer eroor occured */

        that.spinner.hide();
        return stripe.redirectToCheckout({ sessionId: response.id });
      }
    })
    .then(function (result) {
      console.log(result);
      // If redirectToCheckout fails due to a browser or network
      // error, you should display the localized error message to your
      // customer using error.message.
      // alert(result);
    })
    .catch(function (error) {
      console.log(error);
      console.error('Error:', error);
      that.spinner.hide();
    });
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.router.navigate(['/' + this.businessDetails.slug]);
  }
  ngOnDestroy() {
    let businessDetails: any;
    businessDetails = this.comservice.getService('businessDetails');
    this.plocation.onPopState(() => {
      this.router.navigate(['/' + businessDetails.slug]);
      console.log('pressed back!');
    });
  }

  t_and_c() {
    if (this.checked === false) {
      this.spinner.hide();
      this.snackBar.open(this.language.TERMS_ERROR, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    } else {
      return true;
    }
  }

  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
