<div class="close-browser" *ngIf="paypalLoading">
  <p> <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i> {{language.DO_NOT_CLOSE_THE_BROWSER}}</p>
</div>
<div class="checkout-str">
  <div class="container-fluid">
    <div class="checkout">
      <div class="row">
        <div class="col-md-8 col-sm-12 col-xs-12">
          <div class="check-out-form">
            <div class="chk-hdr"> <span class="check-out-dtls_span"> <i class="fa fa-shopping-basket"
                  aria-hidden="true"></i>
              </span>
              <h4>{{language.CHECKOUT}}</h4>
            </div>
            <h5> {{language.BILLING_DETAILS}}
              <span></span>
            </h5>
            <div class="row">
              <div class="col-md-6 col-sm-12" *ngIf="buyerDetails.id == 0">
                <div class="form-group">
                  <input type="text" autocomplete="off" placeholder="{{language.NAME}}" (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.name"
                    required #buyername>
                </div>
              </div>
              <div class="col-md-6 col-sm-12" *ngIf="buyerDetails.id == 0">
                <div class="form-group">
                  <input type="text" autocomplete="off" placeholder="{{language.LAST_NAME}}" (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.last_name" required #buyerlastname>
                </div>
              </div>

              <div class="col-md-12" *ngIf="Search.order_type == '1'">
                <div class="form-group">
                  <input placeholder="{{language.ADDRESS}}" [(ngModel)]="buyerDetails.delievryaddress"
                    [readonly]="addressReadonly" #buyeraddress required>
                </div>
              </div>
              <div class="col-md-12" *ngIf="Search.order_type == '1'">
                <div class="form-group">
                  <input placeholder="{{language.SPECIAL_ADDRESS}}" (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.specialaddress"
                    required #bspecialaddress>
                </div>
              </div>
              <div class="col-md-12" *ngIf="Search.order_type == '1'">
                <div class="form-group">
                  <input placeholder="{{language.EIRCODE}}" (keypress)="omit_special_char($event)"
                    [(ngModel)]="buyerDetails.eircode" required #beircode>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <input autocomplete="off" placeholder="{{language.EMAIL}}" type="text" (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.email" required #buyeremail>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <input autocomplete="off" placeholder="{{language.MOBILE}}" type="text"
                    (keypress)="numberOnly($event)" [(ngModel)]="buyerDetails.cel" required #buyermobile>
                </div>
              </div>
              <div class="col-md-6 col-sm-12" *ngIf="discountInfo.discounttype !== 'offer'">
                <div class="form-group">
                  <input type="text" autocomplete="off" placeholder="{{language.DISCOUNT_COUPON}}"
                    [(ngModel)]="discountInfo.discounttext">
                  <button type="button" class="aply-btn dsabl"
                    [ngClass]="{'enbl': discountInfo.discounttext.length > 0}"
                    (click)="CheckDiscount()">{{language.APPLY}}</button>
                </div>
              </div>
              <div class="col-md-6 col-sm-12"
                *ngIf="Search.order_type == '1' && businessDetails.drivertipsstatus == '1'">
                <div class="form-group">
                  <label>{{language.ORDER_TEMPLATE_DRIVER_TIPS}}</label>
                  <div class="cstm-sldr">
                    <mat-checkbox class="custom-tips" [(ngModel)]="customtips" (change)="showOptions($event)">
                      {{language.CUSTOM_TIPS}}</mat-checkbox>
                    <mat-slider *ngIf="!customtips" thumbLabel [displayWith]="formatLabel" min="0" max="100"
                      [(ngModel)]="tipspercentage" (input)="tipsCalCustom($event)"></mat-slider>
                    <input *ngIf="customtips" type="text" autocomplete="off" placeholder="{{language.ENTER_YOUR_TIPS}}"
                      [(ngModel)]="texttips" (ngModelChange)="TipsCal()" (keypress)="numberOnly($event)">
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-12" *ngIf="Search.order_type == '3'">
                <div class="form-group">
                  <input type="text" autocomplete="off" placeholder="{{language.TABLE_NUMBER}}" [(ngModel)]="buyerDetails.tableNumber" #tablenumber>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 ">
          <div class="your-order">
            <div>
              <h4>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 487.1 487.1"
                  style="enable-background:new 0 0 487.1 487.1;" xml:space="preserve">
                  <g>
                    <g>
                      <path
                        d="M342.3,137.978H385l-63.3-108.6c-5.1-8.8-16.4-11.8-25.2-6.6c-8.8,5.1-11.8,16.4-6.6,25.2L342.3,137.978z" />
                      <path
                        d="M197.4,47.978c5.1-8.8,2.2-20.1-6.6-25.2s-20.1-2.2-25.2,6.6l-63.3,108.7H145L197.4,47.978z" />
                      <path
                        d="M455.7,171.278H31.3c-17.3,0-31.3,14-31.3,31.3v34.7c0,17.3,14,31.3,31.3,31.3h9.8l30.2,163.7 c3.8,19.3,21.4,34.6,39.7,34.6h12h78.8c8,0,18.4,0,29,0l0,0h9.6h9.6l0,0c10.6,0,21,0,29,0h78.8h12c18.3,0,35.9-15.3,39.7-34.6 l30.4-163.8h15.9c17.3,0,31.3-14,31.3-31.3v-34.7C487,185.278,473,171.278,455.7,171.278z M172.8,334.878v70.6 c0,10.1-8.2,17.7-17.7,17.7c-10.1,0-17.7-8.2-17.7-17.7v-29.6v-69.4c0-10.1,8.2-17.7,17.7-17.7c10.1,0,17.7,8.2,17.7,17.7V334.878 z M229.6,334.878v70.6c0,10.1-8.2,17.7-17.7,17.7c-10.1,0-17.7-8.2-17.7-17.7v-29.6v-69.4c0-10.1,8.2-17.7,17.7-17.7 s17.7,8.2,17.7,17.7V334.878z M286.7,375.878v29.6c0,9.5-7.6,17.7-17.7,17.7c-9.5,0-17.7-7.6-17.7-17.7v-70.6v-28.4 c0-9.5,8.2-17.7,17.7-17.7s17.7,7.6,17.7,17.7V375.878z M343.5,375.878v29.6c0,9.5-7.6,17.7-17.7,17.7c-9.5,0-17.7-7.6-17.7-17.7 v-70.6v-28.4c0-9.5,7.6-17.7,17.7-17.7c9.5,0,17.7,7.6,17.7,17.7V375.878z" />
                    </g>
                  </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                  <g> </g>
                </svg> {{language.YOUR_ORDER}}
              </h4>
              <div class="cart-item-grp">
                <div *ngFor="let cartdish of CartDish;let cartindex = index">
                  <div *ngFor="let cdish of cartdish['dish'];let dishindex = index">
                    <div class="cart-item" *ngFor="let dish of cdish['data'];let dataindex = index">
                      <div class="cart-top">
                        <div class="crt-edt">
                          <div class="edt-btns">
                            <span>{{dish.quantity}}</span>
                          </div>
                        </div>
                        <div class="crt-nm-dtls">
                          <h5>{{dish.name}}</h5>
                        </div>

                        <div class="crt-prc">
                          <span>{{businessDetails.currency_symbol}}{{dish.total}}</span>
                        </div>
                      </div>
                      <div class="crt-optn">
                        <p *ngIf="dish.activeIngredients.length > 0">
                          <span>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                              style="enable-background:new 0 0 512 512;" xml:space="preserve">
                              <g>
                                <g>
                                  <polygon
                                    points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 		" />
                                </g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                            </svg>
                          </span> {{language.INGREDIENTS}} - {{dish.activeIngredientsText}}
                        </p>
                        <p *ngFor="let set of dish.sets">
                          <span>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                              style="enable-background:new 0 0 512 512;" xml:space="preserve">
                              <g>
                                <g>
                                  <polygon
                                    points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 		" />
                                </g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                              <g>
                              </g>
                            </svg>
                          </span>{{set.name}} - {{set.choiceText}}
                        </p>
                      </div>
                      <div class="crt-nots" *ngIf="dish.notes != ''">
                        <p>{{dish.notes}}</p>
                      </div>



                      <div *ngIf="businessDetails.buyone_getone_status">
                        <div *ngFor="let fdish of dish.free.data">
                          <div class="cart-top">
                            <div class="crt-nm-dtls">
                              <h5>{{fdish.name}} <small style="color: red;">(Free)</small></h5>
                            </div>
                          </div>
                          <div class="crt-optn">
                            <p *ngIf="fdish.activeIngredients.length > 0" class="optn-lft">
                              <span>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                  style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <polygon
                                        points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 		" />
                                    </g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                </svg>
                              </span> {{language.INGREDIENTS}} - {{fdish.activeIngredientsText}}
                            </p>
                            <p *ngFor="let freeset of fdish.sets" class="optn-lft">
                              <span>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                  style="enable-background:new 0 0 512 512;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <polygon
                                        points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 		" />
                                    </g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                  <g>
                                  </g>
                                </svg>
                              </span> {{freeset.name}} - {{freeset.choiceText}}
                            </p>
                          </div>
                          <div class="crt-nots" *ngIf="fdish.notes != ''">
                            <p>{{fdish.notes}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="ttl">
                <div *ngFor="let cartfee of cartFee">
                  <div class="sub-ttl">
                    <h5>{{language.ORDER_TEMPLATE_SUB_TOTAL}}
                      <span>{{businessDetails.currency_symbol}}{{SubTotal}}</span></h5>
                  </div>
                  <div class="ttl-itm" *ngIf="cartfee.extraminimum > 0">
                    <p>{{language.EXTRA_CHARGE_MINIMUM}}
                      <span>{{businessDetails.currency_symbol}}{{cartfee.extraminimum}}</span></p>
                  </div>
                  <div class="ttl-itm" *ngIf="cartfee.discount > 0">
                    <p>{{language.ORDER_TEMPLATE_DISCOUNT}} <span
                        class="fre">{{businessDetails.currency_symbol}}{{cartfee.discount}}</span></p>
                  </div>
                  <div class="ttl-itm" *ngIf="cartfee.firstoffer > 0">
                    <p>{{language.DISCOUNT_FOR_FIRST_ORDER}}({{businessDetails.firstorderoffer}}%) <span
                        class="fre">{{businessDetails.currency_symbol}}{{cartfee.firstoffer}}</span></p>
                  </div>
                  <div class="ttl-itm" *ngIf="Search.order_type == 1 && cartfee.deliverycost > 0">
                    <p>{{language.DELIEVRY_FEE}} <span>{{businessDetails.currency_symbol}}{{cartFee[0].deliverycost}}</span></p>
                  </div>
                  <div class="ttl-itm" *ngIf="Search.order_type == 1 && cartfee.deliverycost == 0">
                    <p>{{language.DELIEVRY_FEE}} <span class="fre">{{language.FREE}}</span></p>
                  </div>
                  <div class="ttl-itm tl-mg" *ngIf="cartfee.taxprice > 0">
                    <p>{{language.ORDER_TEMPLATE_TAX}}({{cartfee.tax}}%)
                      <span class="">{{businessDetails.currency_symbol}}{{cartfee.taxprice}} </span>
                    </p>
                    <small *ngIf="cartfee.taxtype == '1'">{{language.TAX_INCLUDED}}</small>
                    <small *ngIf="cartfee.taxtype == '0'">{{language.TAX_NOT_INCLUDED}}</small>
                  </div>
                  <div class="ttl-itm ttl-bdr" *ngIf="cartfee.servicefeeprice > 0">
                    <p>{{language.SERVICE_FEE}} ({{cartfee.servicefee}}%)<span
                        class="">{{businessDetails.currency_symbol}}{{cartfee.servicefeeprice}}</span></p>
                  </div>
                  <div class="ttl-itm ttl-bdr" *ngIf="cartfee.tips > 0">
                    <p>{{language.TIPS}}<span class="">{{businessDetails.currency_symbol}}{{cartfee.tips}}</span></p>
                  </div>

                  <div class="ttl-itm ttl-bdr" *ngIf="rewardsData.checked">
                    <p class="line-cut">{{language.ORDER_TEMPLATE_TOTAL}}<span
                        class="">{{businessDetails.currency_symbol}}{{cartfee.total}}</span></p>
                  </div>
                  <div class="ttl-itm ttl-bdr" *ngIf="rewardsData.checked">
                    <p>{{language.PAY_BY_REWARDS}}<span
                        class="">{{businessDetails.currency_symbol}}{{rewardsData.deductPrice}}</span></p>
                  </div>


                  <div class="sub-ttl" *ngIf="rewardsData.checked && rewardsData.amountPrice">
                    <h5>{{language.ORDER_TEMPLATE_TOTAL}}
                      <span>{{businessDetails.currency_symbol}}{{rewardsData.amountPrice}}</span></h5>
                  </div>
                  <div class="sub-ttl" *ngIf="!rewardsData.checked">
                    <h5>{{language.ORDER_TEMPLATE_TOTAL}}
                      <span>{{businessDetails.currency_symbol}}{{cartfee.total}}</span></h5>
                  </div>

                </div>

                <div class="form-group">
                  <textarea (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.notes"
                    placeholder="{{language.BUYER_COMMENT}}"></textarea>
                </div>

                <div class="chsa-ordr" *ngIf="businessDetails.laundrystatus == 1">
                  <label>{{language.PICKUP_DATE_TIME}}</label>
                  <div class="row row-mgn">
                    <div class="col-md-6 col-xs-12">
                      <input class="inp-mg" autocomplete="off" matInput [matDatepickerFilter]="laundryPickupDateFilter"
                        [min]="laundryPickupMinDate" [max]="laundryPickupMaxDate" [matDatepicker]="laundrypicker"
                        (dateChange)="onLaundryPickupValueChange('change', $event.value)" placeholder="Choose a Date"
                        (click)="laundrypicker.open()" (keydown)="false">
                      <mat-datepicker-toggle matSuffix [for]="laundrypicker"></mat-datepicker-toggle>
                      <mat-datepicker [dateClass]="laundryPickupDateClass" #laundrypicker></mat-datepicker>
                    </div>

                    <div class="col-md-6 col-xs-12">
                      <select [(ngModel)]="laundryService.pickupTime"
                        (change)="onLaundryPickupTimeChange()">
                        <option value="">{{language.SELECT_PICKUP_TIME}}</option>
                        <option *ngFor="let opt of laundryPickupMenuTime" [value]="opt">{{opt}}</option>
                      </select>
                    </div>
                  </div>
                  <label>{{language.DELIVERY_DATE_TIME}}</label>
                  <div class="row row-mgn">
                    <div class="col-md-6 col-xs-12">
                      <input class="inp-mg" autocomplete="off" matInput
                        [matDatepickerFilter]="laundryDeliveryDateFilter" [min]="laundryDeliveryMinDate"
                        [max]="laundryDeliveryMaxDate" [matDatepicker]="deliverypicker"
                        (dateChange)="onDeliveryValueChange('change', $event.value)" placeholder="Choose a Date"
                        (click)="deliverypicker.open()" (keydown)="false">
                      <mat-datepicker-toggle matSuffix [for]="deliverypicker"></mat-datepicker-toggle>
                      <mat-datepicker [dateClass]="laundryDeliveryDateClass" #deliverypicker></mat-datepicker>
                    </div>

                    <div class="col-md-6 col-xs-12">
                      <select [(ngModel)]="laundryService.deliveryTime">
                        <option value="">{{language.SELECT_DELIVERY_TIME}}</option>
                        <option *ngFor="let opt of laundryDeliveryMenuTime" [value]="opt">{{opt}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="chsa-ordr" *ngIf="businessDetails.laundrystatus == 0">
                  <div class="from-group alrt" *ngIf="preOrderText === '2' && showPreorderTimeChange == true">
                    <p>{{language.CURRENT_TIME_NOT_MATCHED_WITH_PREORDER}}</p>
                  </div>
                  <label *ngIf="orderType == 1">{{language.DELIVERY_TIME}}</label>
                  <label *ngIf="orderType == 2">{{language.PICKUP_TIME}}</label>
                  <div class="pk-dl">
                    <mat-radio-group class="mat-rdo" [(ngModel)]="preOrderText">
                      <ul>
                        <li [ngClass]="{'active': preOrderText == '1' }" *ngIf="disabledASAP === false">
                          <mat-radio-button value="1" (click)="chnagePreorder(false)">
                            {{language.ASAP}}</mat-radio-button>
                        </li>
                        <li [ngClass]="{'active': preOrderText == '2', 'w-100':  disabledASAP === true }">
                          <mat-radio-button value="2" (click)="chnagePreorder(true)">{{language.PREORDER}}
                          </mat-radio-button>
                        </li>
                      </ul>
                    </mat-radio-group>
                  </div>

                  <div class="row row-mgn" *ngIf="preOrderText == '2'">
                    <div class="col-md-6 col-xs-12">
                      <input class="inp-mg" autocomplete="off" [(ngModel)]="preorderDetails.preorderDate" matInput [matDatepickerFilter]="dateFilter"
                        [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                        (dateChange)="onValueChange('change', $event.value)" placeholder="Choose a Date"
                        (click)="picker.open()" (keydown)="false">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
                    </div>

                    <div class="col-md-6 col-xs-12">
                      <select [(ngModel)]="preOrderTime" (change)="onTimeChange($event)">
                        <option value="">{{language.SELECT_PREORDER_TIME}}</option>
                        <option *ngFor="let opt of businessTime" [value]="opt">{{opt}}</option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="wlt" *ngIf="buyerDetails.id > 0 && rewardsData.totalPoint > 1">
                  <div class="wlt-itm" style="border-right: 1px solid #ccc;">
                    <span>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 469.341 469.341"
                        style="enable-background:new 0 0 469.341 469.341;" xml:space="preserve">
                        <g>
                          <g>
                            <g>
                              <path d="M448.004,236.639v-65.965c0-22.368-17.35-40.559-39.271-42.323l-61.26-107c-5.677-9.896-14.844-16.969-25.813-19.906
                 c-10.917-2.917-22.333-1.385-32.104,4.302L79.553,128.007H42.67c-23.531,0-42.667,19.135-42.667,42.667v256
                 c0,23.531,19.135,42.667,42.667,42.667h362.667c23.531,0,42.667-19.135,42.667-42.667v-65.965
                 c12.389-4.418,21.333-16.147,21.333-30.035v-64C469.337,252.786,460.393,241.057,448.004,236.639z M383.944,128.007h-92.971
                 l69.729-40.596L383.944,128.007z M350.103,68.898l-101.529,59.109h-42.113l133.112-77.5L350.103,68.898z M300.295,24.184
                 c4.823-2.823,10.458-3.573,15.844-2.135c5.448,1.458,9.99,4.979,12.813,9.906l0.022,0.039l-164.91,96.013h-42.111L300.295,24.184
                 z M426.67,426.674c0,11.76-9.573,21.333-21.333,21.333H42.67c-11.76,0-21.333-9.573-21.333-21.333v-256
                 c0-11.76,9.573-21.333,21.333-21.333h362.667c11.76,0,21.333,9.573,21.333,21.333v64h-64c-35.292,0-64,28.708-64,64
                 c0,35.292,28.708,64,64,64h64V426.674z M448.004,330.674c0,5.885-4.781,10.667-10.667,10.667H362.67
                 c-23.531,0-42.667-19.135-42.667-42.667c0-23.531,19.135-42.667,42.667-42.667h74.667c5.885,0,10.667,4.781,10.667,10.667
                 V330.674z" />
                              <path d="M362.67,277.341c-11.76,0-21.333,9.573-21.333,21.333c0,11.76,9.573,21.333,21.333,21.333
                 c11.76,0,21.333-9.573,21.333-21.333C384.004,286.914,374.431,277.341,362.67,277.341z" />
                            </g>
                          </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </svg>
                    </span>
                    <div>
                      <h6>{{language.REWARDS_WALLET}}</h6>
                      <p>{{language.TOTAL_BALANCE}}: {{businessDetails.currency_symbol}}{{rewardsData.totalPrice}}</p>
                    </div>
                  </div>
                  <div class="wlt-itm" style="padding-left: 15px;">
                    <span style="margin-right: 0px;">
                      <div class="icheck-material-pink ">
                        <input type="checkbox" id="rewards-check" [(ngModel)]="rewardsData.checked"
                          (click)="checkReward()">
                        <label for="rewards-check"></label>
                      </div>
                    </span>
                    <div *ngIf="rewardsData.checked">
                      <h6>{{businessDetails.currency_symbol}}{{rewardsData.deductPrice}}</h6>
                      <p class="use">{{language.DEDUCT_PRICE_WALLET}}</p>
                    </div>
                  </div>
                </div>

                <div class="chs-paymnt" *ngIf="paymentEnabled == true">
                  <label>{{language.PAYMENT_METHOD}}</label>
                  <mat-radio-group class="mat-rdo" [(ngModel)]="paymentId">
                    <ul>
                      <li *ngFor="let pay of paymentMethod">
                        <mat-radio-button (click)="SelectPayment(pay)" value="{{pay.id}}">
                          {{pay.displayName}}</mat-radio-button>
                      </li>
                    </ul>
                  </mat-radio-group>
                </div>
              </div>

              <div class="form-group">
                <div class="TandC">
                  <mat-checkbox [(ngModel)]="checked"></mat-checkbox>
                  <span>{{language.TERMS_1}}<b>
                    <a href="{{language.TERMS_1_LINK}}" target="_blank">{{language.TERMS_CONDITIONS}}</a>
                  </b></span>
                </div>
              </div>

              <button [ngClass]="{'displayNone': paypalExpress == true }" style=" width: 100%;"
                class="sbm-btn cmn-button" (click)="CheckallDetails()">{{language.PLACE_ORDER}}</button>
              <div id="paypal-button-container" [ngClass]="{'displayNone': paypalExpress == false}"></div>
              <input type="number" [(ngModel)]="paypalTotal" style="display:none">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
