<section id="confirmation">
  <div class="container">
    <div class="row" *ngIf="orderview == true">
      <div class="col-md-8 col-sm-12 col-xs-12">
        <div class="cnfrm-lft">
          <div class="cnfrm-msg">
            <span>
              <img src="../../assets/images/dlvry.png" />
            </span>
            <h5>
              <img src="../../assets/images/tik.png" /> {{language.THANK_YOU_ORDER_RECEIVED}}</h5>
          </div>
          <div class="cnfrm-dtls" style="margin-bottom: 20px;">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th><span>{{language.ORDER_NUMBER}}</span></th>
                  <th><span>{{language.DATE}}</span></th>
                  <th><span>{{language.ORDER_TEMPLATE_TOTAL}}</span></th>
                  <th><span>{{language.PAYMENT_METHOD}}</span></th>
                </tr>
                <tr>
                  <td>#{{order.id}}</td>
                  <td>{{order.date}}</td>
                  <td>{{order.currency_symbol}} {{order.total}}</td>
                  <td *ngIf="order.reward_wallet == 1 && order.payment_id > 0">{{language.WALLET}},
                    {{order.paymentname}}</td>
                  <td *ngIf="order.reward_wallet == 1 && order.payment_id == 0">{{language.WALLET}}</td>
                  <td *ngIf="order.reward_wallet == 0 && order.payment_id > 0">{{order.paymentname}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="cnfrm-dtls">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th style="width: 30%;"><span>{{language.ITEM_NAME}}</span></th>
                  <th style="width: 10%;"><span>{{language.QUANTITY}}</span></th>
                  <th style="width: 20%;"><span>{{language.INGREDIENTS}}</span></th>
                  <th style="width: 25%;"><span>{{language.OPTIONS}}</span></th>
                  <th style="width: 15%;"><span>{{language.PRICE}}</span></th>
                </tr>
                <tbody *ngFor="let dishdata of order.dishdata" style="border: 0px !important">
                  <tr *ngFor="let dish of dishdata.data">
                    <td style="width: 30%;">{{dish.name}}</td>
                    <td style="width: 10%;">{{dish.quantity}}</td>
                    <td style="width: 20%;">{{dish.activeIngredientsText}} </td>
                    <td style="width: 25%;"><span *ngFor="let set of dish.sets">{{set.name}} - {{set.choiceText}}</span>
                    </td>
                    <td style="width: 15%;">{{order.currency_symbol}} {{dish.total}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="cnfrm-map"  *ngIf="order.order_type == '1'">
            <agm-map [latitude]="order.blocation.latitud" [longitude]="order.blocation.longitud">
              <agm-direction [origin]="origin" [destination]="destination">
              </agm-direction>
            </agm-map>
          </div>
          <div class="blng-adrs">
            <div class="blng-adrs-item">
              <h5>{{language.BILLING_ADDRESS}}</h5>
              <p><span><i class="fa fa-user" aria-hidden="true"></i></span>{{order.buyerdata.name}}
                {{order.buyerdata.last_name}}</p>
              <p *ngIf="order.order_type == '1'"><span><i class="fa fa-map-marker"
                    aria-hidden="true"></i></span>{{order.buyerdata.delievryaddress}}</p>
              <p *ngIf="order.order_type == '1'"><span><i class="fa fa-map-marker"
                    aria-hidden="true"></i></span>{{order.buyerdata.specialaddress}}</p>
              <p><span><i class="fa fa-phone" aria-hidden="true"></i></span><a
                  href="tel:{{order.buyerdata.cel}}">{{order.buyerdata.cel}}</a>
              </p>
              <p><span><i class="fa fa-envelope" aria-hidden="true"></i></span><a
                  href="mailto:{{order.buyerdata.email}}">{{order.buyerdata.email}}</a>
              </p>
            </div>
            <div class="blng-adrs-item">
              <h5>{{language.BUSINESS_DETAILS}}</h5>
              <p>{{order.bname}}</p>
              <p><span><i class="fa fa-map-marker" aria-hidden="true"></i></span>{{order.baddress}}</p>
              <p><span><i class="fa fa-phone" aria-hidden="true"></i></span><a
                  href="tel:+{{order.bmobile}}">+{{order.bmobile}}</a></p>
              <p><span><i class="fa fa-envelope" aria-hidden="true"></i></span><a
                  href="mailto:{{order.bemail}}">{{order.bemail}}</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <div class="cnfrm-ryt">
          <div class="status-grph">
            <h4>{{language.UPDATE_ORDER_STATUS}}</h4>
            <p class="status-grph-pending">{{language.PENDING}}</p>
          </div>
          <div class="time-drtn" *ngIf="order.buyerdata.preorder">
            <h5 *ngIf="order.order_type == '1'">{{language.WE_WILL_DELIVER_WITHIN_PREORDER}}</h5>
            <h5 *ngIf="order.order_type == '2'">{{language.WE_WILL_DELIVER_WITHIN_PICKUP_PREORDER}}</h5>
            <span><img src="../../assets/images/clock.png" /></span>
            <p>{{order.estimate_time_min}}</p>
          </div>
          <div class="time-drtn" *ngIf="!order.buyerdata.preorder">
            <h5 *ngIf="order.order_type == '1'">{{language.WE_WILL_DELIVER_WITHIN}}</h5>
            <h5 *ngIf="order.order_type == '2'">{{language.WE_WILL_DELIVER_WITHIN_PICKUP}}</h5>
            <span><img src="../../assets/images/clock.png" /></span>
            <p>{{order.estimate_time_min}} {{language.MIN}}</p>
          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="bookreview == true">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="cnfrm-lft">
          <div class="cnfrm-msg">
            <span><img src="../../assets/images/dlvry.png" /></span>
            <h5><img src="../../assets/images/tik.png" /> {{language.BOOKING_HAS_RECEIVED}}</h5>
          </div>
          <div class="cnfrm-dtls">
            <div class="table-responsive">
              <table class="table">
                <tr>
                  <th><span>{{language.BOOKED_NUMBER}}</span></th>
                  <th><span>{{language.BOOKING_DATE_TIME}}</span></th>
                  <th><span>{{language.PERSON}}</span></th>
                </tr>
                <tr>
                  <td>#{{order.id}}</td>
                  <td>{{order.bookingdate}} - {{order.bookingtime}}</td>
                  <td>{{order.person}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="blng-adrs">
            <div class="blng-adrs-item">
              <h5>{{language.USER_DETAILS}}</h5>
              <p><span><i class="fa fa-user" aria-hidden="true"></i></span>{{order.name}}</p>
              <p><span><i class="fa fa-phone" aria-hidden="true"></i></span><a
                  href="tel:{{order.cel}}">{{order.cel}}</a></p>
              <p><span><i class="fa fa-envelope" aria-hidden="true"></i></span><a
                  href="mailto:{{order.email}}">{{order.email}}</a></p>
            </div>
            <div class="blng-adrs-item">
              <h5>{{language.BUSINESS_DETAILS}}</h5>
              <p>{{order.bname}}</p>
              <p><span><i class="fa fa-map-marker" aria-hidden="true"></i></span>{{order.baddress}}</p>
              <p><span><i class="fa fa-phone" aria-hidden="true"></i></span><a
                  href="tel:+{{order.bmobile}}">+{{order.bmobile}}</a></p>
              <p><span><i class="fa fa-envelope" aria-hidden="true"></i></span><a
                  href="mailto:{{order.bemail}}">{{order.bemail}}</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
